import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Input as InputFile,
  Label,
} from "reactstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createAttributes, editAttributes } from "../../../api";
import { getCategoriesQuery, getAttributeQuery } from "../../../queries";
import { Input } from "../../../Components/atoms/input";
import { useNavigate, useParams } from "react-router-dom";
import {
  findParentId,
  dataTypeIndex,
} from "../../../helpers/findParentCategoryId";
import { toast, ToastContainer } from "react-toastify";
import Select from "react-select";
import { Controller } from "react-hook-form";
import RadioButtonInput from "../ui/radio-button";

const schema = createSchema({
  nameru: "name",
  "nameuz-Latn-UZ": "name",
  "nameuz-Cyrl-UZ": "name",
  descriptionru: "name",
  "descriptionuz-Latn-UZ": "name",
  "descriptionuz-Cyrl-UZ": "name",
  weight: "number",
});

const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  hasFilter: "",
  isRequired: "",
  isVisible: "",
  weight: "",
  dataType: "",
  categoryId: "",
  isValueTranslated: "",
  filterId: "",
};

const AttributeCategory = () => {
  const [onChange, setOnChange] = useState("");
  const [filtersData, setFiltersData] = useState([]);
  const [hasFilter, setHasFilter] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isValueTranslated, setIsValueTranslated] = useState(false);
  const [filterDataType, setFilterDataType] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const QueryClient = useQueryClient();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
    setValue,
  } = useHookForm(values, schema);

  // FETCHING
  const { data: categories } = useQuery({
    ...getCategoriesQuery(),
  });
  const { data: attribute } = useQuery({
    ...getAttributeQuery(id),
    enabled: id !== undefined,
  });

  const FiltersDataFetch = async () => {
    const categoryId =
      watch("childCategory")?.value || watch("parentCategory")?.value;
    if (categoryId) {
      fetch(
        `
    https://f.taqsim.uz/api/v1/filters?categoryId=${categoryId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setFiltersData(res.item);
          res.item.filter(
            (filter) =>
              filter.id === watch("filterId") &&
              (setValue("filterId", { value: filter.id, label: filter.name }),
              setValue("dataType", {
                value: filter.dataType,
                label: filter.dataType,
              }))
          );
        });
    }
  };

  const onSubmit = handleSubmit((res) => {
    if (!watch("parentCategory")) {
      return setError("parentCategory", {
        type: "manual",
        message: t("required"),
      });
    }
    if (hasFilter) {
      if (!watch("filterId")) {
        return setError("filterId", {
          type: "manual",
          message: t("required"),
        });
      }
    } else {
      if (!watch("dataType")) {
        return setError("dataType", {
          type: "manual",
          message: t("required"),
        });
      }
    }
    setValue("nameru", res.nameru);
    setValue("nameuz-Latn-UZ", res["nameuz-Latn-UZ"]);
    setValue("nameuz-Cyrl-UZ", res["nameuz-Cyrl-UZ"]);
    setValue("descriptionru", res.descriptionru);
    setValue("descriptionuz-Latn-UZ", res["descriptionuz-Latn-UZ"]);
    setValue("descriptionuz-Cyrl-UZ", res["descriptionuz-Cyrl-UZ"]);

    const names = Object.keys(res.name).map((languageCode) => ({
      languageCode,
      text: watch(`name${languageCode}`),
    }));
    const descriptions = Object.keys(res.description).map((languageCode) => ({
      languageCode,
      text: watch(`description${languageCode}`),
    }));
    setLoading(true);
    const datas = {
      name: names,
      categoryId:
        res?.grandSonCategory?.value ||
        res?.childCategory?.value ||
        res?.parentCategory?.value,
      description: descriptions,
      dataType: res.dataType?.value || filterDataType,
      weight: parseInt(res.weight),
      isRequired,
      hasFilter,
      isVisible,
      isValueTranslated,
      ...(hasFilter && { filterId: res.filterId.value }),
      ...(id && { id: parseInt(id) }),
    };
    id
      ? editAttributes(datas)
          .then(() => {
            setLoading(false);
            navigate("/attributes");
            QueryClient.invalidateQueries(["attributes"]);
            toast("Аттрибут изменен", {
              type: "success",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          })
          .catch((error) => {
            setLoading(false);
            toast("Что-то пошло не так", {
              type: "error",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          })
      : createAttributes(datas)
          .then(() => {
            setLoading(false);
            navigate("/attributes");
            QueryClient.invalidateQueries(["attributes"]);
            toast("Аттрибут добавлен", {
              type: "success",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          })
          .catch((error) => {
            setLoading(false);
            toast("Что-то пошло не так", {
              type: "error",
              theme: "colored",
              position: "top-center",
              autoClose: "2000",
            });
          });
  });
  useEffect(() => {
    setValue("childCategory", "");
    setValue("grandSonCategory", "");
    setValue("filterId", "");
  }, [onChange]);

  useEffect(() => {
    if (attribute) {
      attribute.name.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      attribute.description.map((item) =>
        setValue(`description${item.languageCode}`, item.text)
      );
      setValue(
        "parentCategory",
        findParentId(categories, attribute?.categoryId)?.parent ||
          findParentId(categories, attribute?.categoryId)
      );
      setValue(
        "childCategory",
        findParentId(categories, attribute.categoryId)?.child || {}
      );
      setValue(
        "grandSonCategory",
        findParentId(categories, attribute.categoryId)?.grandSon || {}
      );
      setHasFilter(attribute.hasFilter);
      setIsRequired(attribute.isRequired);
      setIsVisible(attribute.isVisible);
      setIsValueTranslated(attribute.isValueTranslated);
      setValue("dataType", {
        value: dataTypeIndex(attribute.dataType),
        label: dataTypeIndex(attribute.dataType),
      });
      setValue("weight", attribute.weight);
      setValue("filterId", attribute.filterId);
      FiltersDataFetch();
    }
  }, [attribute]);

  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col className="d-flex justify-content-center">
            <Card className="w-75">
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {id ? t("edit_attribute") : t("add_attribute")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <Label>{t("change_category")}</Label>
                  <Controller
                    name="parentCategory"
                    control={control}
                    className="bg-white"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={categories?.map((item) => ({
                          value: item.id,
                          label: item.name,
                          array: item,
                        }))}
                        placeholder={t("Select parent category")}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          setOnChange(selectedOption.value);
                        }}
                      />
                    )}
                  />
                  {errors.parentCategory && (
                    <span className="text-danger">
                      {errors.parentCategory.message}
                    </span>
                  )}
                  {watch("parentCategory")?.array?.childs !== null && (
                    <Controller
                      name="childCategory"
                      control={control}
                      className="bg-white"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={watch("parentCategory")?.array?.childs?.map(
                            (item) => ({
                              value: item.id,
                              label: item.name,
                              array: item,
                            })
                          )}
                          placeholder={t("Select child category")}
                        />
                      )}
                    />
                  )}
                  {watch("childCategory")?.array &&
                    watch("childCategory")?.array?.childs !== null && (
                      <Controller
                        name="grandSonCategory"
                        control={control}
                        className="bg-white"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={watch("childCategory")?.array?.childs?.map(
                              (item) => ({
                                value: item.id,
                                label: item.name,
                              })
                            )}
                            placeholder={t("Select child category")}
                          />
                        )}
                      />
                    )}
                  {/* FILTERS */}
                  <RadioButtonInput
                    label={"filters"}
                    state={hasFilter}
                    setState={setHasFilter}
                    id={2}
                  />

                  {hasFilter ? (
                    <>
                      <Label>{t("change_filter")}</Label>
                      <Controller
                        name="filterId"
                        control={control}
                        className="bg-white"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={filtersData?.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            placeholder={t("Select FILTER")}
                            onChange={(selectedOption) => {
                              const type = filtersData?.filter(
                                (item) => item.id === selectedOption.value
                              );
                              field.onChange(selectedOption);
                              setFilterDataType(type[0].dataType);
                            }}
                            onFocus={FiltersDataFetch}
                          />
                        )}
                      />
                      {errors.filterId && (
                        <span
                          style={{ fontSize: "0.71rem" }}
                          className="text-danger"
                        >
                          {t(errors.filterId.message)}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <Label>{t("dataType")}</Label>
                      <Controller
                        name="dataType"
                        control={control}
                        className="bg-white"
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              { value: "Text", label: "Text" },
                              { value: "Integer", label: "Integer" },
                              {
                                value: "Float",
                                label: "Float",
                              },
                              { value: "Date", label: "Date" },
                              { value: "Bool", label: "Bool" },
                              { value: "Char", label: "Char" },
                            ]}
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                            }}
                          />
                        )}
                      />
                      {errors.dataType && (
                        <span
                          style={{ fontSize: "0.71rem" }}
                          className="text-danger"
                        >
                          {t(errors.dataType.message)}
                        </span>
                      )}
                    </>
                  )}
                  <Input
                    control={control}
                    errors={errors}
                    name="weight"
                    label="weight"
                    isInteger
                    maxLength={1}
                  />
                  <RadioButtonInput
                    label={"isValueTranslated"}
                    state={isValueTranslated}
                    setState={setIsValueTranslated}
                    disabled={watch("dataType").value === "Integer"}
                    dataType={watch("dataType").value === "Integer"} 
                    id={4}
                  />
                  <RadioButtonInput
                    label={"required_field_error"}
                    state={isRequired}
                    setState={setIsRequired}
                    id={1}
                  />
                  <RadioButtonInput
                    label={"isVisible"}
                    state={isVisible}
                    setState={setIsVisible}
                    id={3}
                  />
                  {/* NAME & DESCRITPION TRANSLATE */}
                  <Input
                    control={control}
                    errors={errors}
                    name="nameru"
                    label="name_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Cyrl-UZ"
                    label="name_uz-Cyrl-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="nameuz-Latn-UZ"
                    label="name_uz-Latn-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Input
                    control={control}
                    errors={errors}
                    name="descriptionru"
                    label="description_ru"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Input
                    control={control}
                    errors={errors}
                    name="descriptionuz-Cyrl-UZ"
                    label="description_uz-Cyrl-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Input
                    control={control}
                    errors={errors}
                    name="descriptionuz-Latn-UZ"
                    label="description_uz-Latn-UZ"
                    inputProps={{
                      placeholder: t("enter_name"),
                    }}
                  />

                  <Button
                    color="success"
                    className="btn btn-success w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttributeCategory;
